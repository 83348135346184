import { PageContextProps } from 'application/contexts/PageContext';
import { queryCmsContent } from './_api';

export const getSiteFromCms = async (pageContext: Partial<PageContextProps>): Promise<Models.Site> => {
	const query = `{
		site: page(path: "/")
		{
			id
			culture
			properties
		}
	}`;

	const data = await queryCmsContent({
		query,
		pageContext,
		requestType: 'site',
	});

	const { siteSettings, siteTitle, siteTheme, redirectLink } = data?.site?.properties ?? {};
	const findSetting = (documentType) => siteSettings?.find((e) => e.documentType === documentType)?.content.properties || null;

	return {
		culture: data?.site?.culture ?? null,
		title: siteTitle ?? null,
		theme: siteTheme ?? null,
		settings: {
			chatbot: findSetting('chatbot'),
			segmentAreas: findSetting('segmentAreas'),
			languagePicker: findSetting('languagePicker'),
			loginConfiguration: findSetting('loginConfiguration'),
			searchConfiguration: findSetting('searchConfiguration'),
			globalLinks: findSetting('globalLinks'),
			readSpeaker: findSetting('readSpeaker'),
			adobeTracking: findSetting('adobeTracking'),
			contactBoxWaitTime: findSetting('contactBoxWaitTime'),
			homePageRedirectUrl: redirectLink?.url || null,
			applicationInsights: findSetting('applicationInsights'),
			experimental: findSetting('experimental'),
		},
	};
};
