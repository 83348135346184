import { getDictionaryItems } from 'application/repositories/dictionaryRepository';
import { getPageContent } from 'application/repositories/pageContentRepository';
import { getSite } from 'application/repositories/siteRepository';
import Error404 from 'features/Errors/404';
import ErrorGeneric from 'features/Errors/Generic';
import { ApiRedirect } from 'helpers/apiRedirect';
import logger from 'utilities/logger';

type PageErrorProps = {
	content: Content.PageContent;
	navigation: Navigation.NavigationItem;
	dictionary: Content.DictionaryItem[];
	site: Models.Site;
	page: Content.Page;
	siteTheme: Content.SiteTheme[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	root: any;
	host: string;
	statusCode: number;
	statusMessage: string;
	error: string;
};

const Error = (pageProps: PageErrorProps): unknown => {
	const { statusCode, statusMessage, error } = pageProps ?? {};
	return statusCode === 404 ? (
		<Error404 {...pageProps} breadcrumb={pageProps.content.breadcrumb} />
	) : (
		<ErrorGeneric {...{ statusCode }} {...{ statusMessage }} {...{ error }} />
	);
};

// function
Error.getInitialProps = async (context) => {
	const { req, res, err, preview, previewData } = context;

	const originalHost = req?.headers['x-original-host'];
	const hostName =
		originalHost != undefined ? originalHost : Array.isArray(req?.headers?.host) ? req.headers?.host.split(':')[0] : req?.headers?.host;

	const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
	const statusMessage = res ? res.statusMessage : err ? err.message : null;

	if (err instanceof ApiRedirect) {
		res.writeHead(err?.statusCode, {
			'Cache-Control': 'no-cache, no-store, must-revalidate',
			Expires: '0',
			Pragma: 'no-cache',
			Location: err.url || '',
		});
		res.end();
	}

	if (statusCode === 404) {
		const path = '/404';
		const pageContext = { host: hostName, preview, previewData };
		try {
			const dictionaryPromise = getDictionaryItems({ pageContext });
			const sitePromise = getSite({ pageContext });
			const contentPromise = getPageContent({
				url: path,
				pageContext,
			});

			return {
				statusCode,
				statusMessage,
				error: err,
				content: await contentPromise,
				dictionary: await dictionaryPromise,
				site: await sitePromise,
				host: hostName,
			};
		} catch (error) {
			logger.error(error);
			throw error;
		}
	} else {
		return {
			statusCode,
			statusMessage,
			error: err,
		};
	}
};

export default Error;
