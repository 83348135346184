import styles from './Footer.module.scss';

export interface FooterProps {
	children: React.ReactNode;
}

export const Footer: React.FC<FooterProps> = ({ children }) => (
	<>
		<footer className={styles.Footer}>{children}</footer>
	</>
);
