import classNames from 'classnames';
import { Picture2 } from 'components/1-atoms/Media/Picture2/Picture2';
import { useEffect, useState } from 'react';
import { aspectRatioAsNumber } from 'utilities/aspectRatioAsNumber';
import { ContentCard } from '../ContentCard';
import styles from './HeroCard.module.scss';

export interface HeroCardProps {
	className?: string;
	content?: {
		heading: string;
		headingSubtext?: string;
		text?: string;
		link?: React.ReactElement;
	};
	fullWidthImage?: boolean;
	hideImageOnMobile?: boolean;
	image: UI.Image;
	imageAspectRatio?: '16:3' | '16:6' | '16:9';
	noTopMargin?: boolean;
}

export const HeroCard: React.FC<HeroCardProps> = ({
	className,
	content,
	fullWidthImage,
	hideImageOnMobile,
	image,
	imageAspectRatio = '16:6',
	noTopMargin,
}) => {
	const [cropImageAspectRatio, setCropImageAspectRatio] = useState<number>(0);
	const [maxImageSize, setMaxImageSize] = useState<number>(1400);

	useEffect(() => {
		if (image.aspectRatio < aspectRatioAsNumber(imageAspectRatio)) {
			setMaxImageSize(1700);
		}

		setCropImageAspectRatio(aspectRatioAsNumber(imageAspectRatio));
	}, [image, imageAspectRatio]);

	return (
		<div
			className={classNames(
				styles.HeroCard,
				{ [styles.HeroCard___noTopMargin]: noTopMargin },
				styles[`HeroCard___${imageAspectRatio.replace(':', 'x')}`],
				className,
			)}
		>
			<div className={styles.HeroCard_wrapper}>
				<div className={classNames(styles.HeroCard_media, { [styles.HeroCard_media___fullWidth]: fullWidthImage },)} aria-hidden={true}>
					<span className={hideImageOnMobile ? styles.HeroCard_media___hideOnMobile : ''}>
						<Picture2
							altText={image.altText}
							aspectRatio={cropImageAspectRatio}
							focalPoint={image.focalPoint}
							isCover
							sizes={fullWidthImage ? '100vm' : `(min-width: 1300px) ${maxImageSize}px, 100vw`}
							url={image.url}
						/>
					</span>
				</div>
				{content && (
					<div className={styles.HeroCard_content}>
						<ContentCard heading={content.heading} headingSubtext={content.headingSubtext} text={content.text} link={content.link} />
					</div>
				)}
			</div>
		</div>
	);
};
