import { PageContextProps } from 'application/contexts/PageContext';
import { queryCmsContent } from './_api';

export const getPageContentFromCms = async (requestUrl: string, pageContext: Partial<PageContextProps>): Promise<Content.PageContent> => {
	const query = `{
		root: page(path: "/")
		{
			id
			properties(fields: ["siteSettings", "siteTheme", "navigationItems"]) 
		}
		page: page {
			id
			name
			documentType
			url
			properties
			childPages: children {
				id
				documentType
				name
				url
				properties(fields:["umbracoNaviHide", "navigationTitle", "navigationDescription", "highlighted"])
			}
			template
			culture
			parentId
			pageLanguageVersions { 
				cultureName 
				pageUrl
			}
			lastUpdated
			createDate
		}
		breadcrumb
		footerHeading: field(path:"${requestUrl}", field: "footerHeading") 
		footerContent: field(path:"${requestUrl}", field: "footerContent") 
		segment: field(path:"${requestUrl}", field: "segment") 
		hideDates: field(path:"${requestUrl}", field: "hideDates") 
	}`;

	const data = await queryCmsContent({
		query,
		pageContext,
		url: requestUrl,
		requestType: 'pageContent',
	});

	const pageContent: Content.PageContent = {
		...data,
		footer: {
			heading: data?.footerHeading?.footerHeading || null,
			content: data?.footerContent?.footerContent || null,
		},
		segment:
			data?.segment?.segment?.length > 0
				? data.segment.segment.map((segment) => {
						return {
							name: segment?.properties?.segmentName || null,
							trackingName: segment?.properties?.trackingName || null,
							id: segment?.id || null,
						};
				  })
				: null,
		configuration: {
			hideDates: data?.hideDates?.hideDates || null,
		},
	};
	return pageContent;
};
