import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { SearchCard, SearchCardProps } from 'designsystem/components/2-molecules/Card';
import { LayoutFeature } from 'features/Layouts/Layout/LayoutFeature';
import { CludoSearchInputFeature } from 'features/Modules/CludoSearchInput';
import { LinkFeature } from 'features/Modules/Link';
import { PageTypeFeature } from 'features/Modules/PageType/PageTypeFeature';
import { DXPContentPage } from 'pages/_app';

const Error404: DXPContentPage<PageProps> = (pageProps) => {
	const dictionary = useDictionaryContext();

	const { page, site } = pageProps ?? {};
	const { privacyLink } = site?.settings?.globalLinks ?? {};
	const searchCardProps: SearchCardProps = {
		teaser: dictionary.getSearch('TrySearch'),
		text: dictionary.getSearch('Disclaimer'),
		link: privacyLink ? <LinkFeature {...privacyLink} /> : null,
		searchInput: <CludoSearchInputFeature ariaLabel="search-input" id="cludo-search-content-form2"></CludoSearchInputFeature>,
	};
	return (
		<LayoutFeature {...pageProps}>
			<PageTypeFeature {...page}>
				<SearchCard {...searchCardProps} />
			</PageTypeFeature>
		</LayoutFeature>
	);
};

type PageProps = {
	content: Content.PageContent;
	navigation: Navigation.NavigationItem;
	dictionary: Content.DictionaryItem[];
	site: Models.Site;
	host: string | string[];
};

export default Error404;
