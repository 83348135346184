import { RequestInterface } from 'application/connectors/cms/_api';
import { getPageContentFromCms } from 'application/connectors/cms/pageContentConnector';
import { ApiError } from 'helpers/apiError';
import { axiosInstance as axios } from 'helpers/axiosInstance';

export const getPageContent = async ({ url, pageContext }: RequestInterface): Promise<Content.PageContent> => {
	return await getPageContentFromCms(url, pageContext);
};

export interface Page404DataResponse {
	pageContent: Content.PageContent;
	site: Models.Site;
}

export const get404PageContent = async (): Promise<Page404DataResponse> => {
	try {
		const res = await axios('/api/get404content');
		return res.data as Page404DataResponse;
	} catch (err) {
		const errorCode = err?.response?.status ?? 500;
		const errorMessage = err?.message ?? 'Unknown error';
		throw new ApiError('[genericRequest]', errorCode, `Request to '/404 threw an error: ${errorMessage}`);
	}
};
