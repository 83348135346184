import { ErrorPage } from 'designsystem/components/5-templates/ErrorPage';
import { LogoFeature } from 'features/Modules/Logo';
import { NextPage } from 'next';
import logger from 'utilities/logger';

const ErrorGeneric: NextPage<PageProps> = (pageProps) => {
	const { statusCode, statusMessage } = pageProps;
	logger.info('Rendering Generic Error with SSG/SSR GENERIC');

	// return <h1>Generic Error - {statusCode} error occurred</h1>;
	return (
		<ErrorPage
			errorCode={statusCode}
			message={statusMessage}
			logo={<LogoFeature />}
			enforcedFullLogo={<LogoFeature dark enforceFullLogo />}
			darkLogo={<LogoFeature dark />}
		/>
	);
};

type PageProps = {
	statusCode?: number;
	statusMessage?: string;
	error?: unknown;
};

export default ErrorGeneric;
