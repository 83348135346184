import { RichText } from 'components/1-atoms/RichText';
import { ContentCard } from 'components/2-molecules/Card';
import { Footer } from 'components/2-molecules/Footer';
import { Container, ContainerWidth } from 'components/4-habitats/Container';
import { FooterSection } from 'components/4-habitats/Footer';
import { Header, HeaderProps } from 'components/4-habitats/Header';
import { Main } from 'components/4-habitats/Main';
import { TopArea, TopAreaProps } from 'components/4-habitats/TopArea';

export interface ErrorPageProps extends HeaderProps {
	errorCode: 404 | 500 | number;
	message: string;
	additionalTop?: TopAreaProps['additional'];
	additionalPageContent?: React.ReactNode;
	pageWidth?: ContainerWidth;
}

export const ErrorPage: React.FC<ErrorPageProps> = ({
	errorCode,
	message,
	additionalTop,
	additionalPageContent,
	pageWidth = 'Medium',
	...headerProps
}) => {
	return (
		<>
			<Header {...headerProps} />
			<Main>
				<TopArea additional={additionalTop} />
				{additionalPageContent}
				<Container width={pageWidth}>
					<ContentCard heading={errorCode?.toString()} headingSubtext={message} />
				</Container>
			</Main>
			<Footer>
				<FooterSection>
					<RichText content="<p>Error</p>" />
				</FooterSection>
			</Footer>
		</>
	);
};
